import type { Fetcher } from 'swr'
import { ContentType, del, get, patch, post, put } from './base'
import type { ApikeysListResponse, AppDailyConversationsResponse, AppDailyEndUsersResponse, AppDailyMessagesResponse, AppDetailResponse, AppListResponse, AppSSOResponse, AppStatisticsResponse, AppTemplatesResponse, AppTokenCostsResponse, AppVoicesListResponse, AverageSessionInteractionsResponse, AvgChatDurationResponse, CommodityCardConversionFunnelResponse, ConsultationIntentionDistributionResponse, ConsultationProductDistributionResponse, ConsultationScenarioDistributionResponse, ConversationAndMessageCountResponse, CreateApiKeyResponse, GenerationIntroductionResponse, IntentAndSkuListResponse, InteractionAIUserCountResponse, LaborTransferRateChartResponse, LackKnowledgeIntentionDistributionResponse, LackKnowledgeProductDistributionResponse, LackKnowledgeRateResponse, ReachOutChannelDistributionResponse, ReachOutReasonDistributionResponse, ReachOutUserCountDistributionResponse, TracingConfig, TracingStatus, UpdateAppModelConfigResponse, UpdateAppSiteCodeResponse, UpdateOpenAIKeyResponse, UserFeedbackDistributionResponse, UserSatisfactionRateResponse, ValidateOpenAIKeyResponse, WorkflowDailyConversationsResponse } from '@/models/app'
import type { CommonResponse } from '@/models/common'
import type { AppIconType, AppMode, BotType, Language, ModelConfig } from '@/types/app'
import type { TracingProvider } from '@/app/(commonLayout)/app/(appDetailLayout)/[appId]/overview/tracing/type'

export const fetchAppList: Fetcher<AppListResponse, { url: string; params?: Record<string, any> }> = ({ url, params }) => {
  return get<AppListResponse>(url, { params })
}

export const fetchAppDetail = ({ url, id }: { url: string; id: string }) => {
  return get<AppDetailResponse>(`${url}/${id}`)
}

export const fetchAppSSO = async ({ appId }: { appId: string }) => {
  return get<AppSSOResponse>(`/enterprise/app-setting/sso?appID=${appId}`)
}
export const updateAppSSO = async ({ id, enabled }: { id: string; enabled: boolean }) => {
  return post('/enterprise/app-setting/sso', { body: { app_id: id, enabled } })
}

export const fetchAppTemplates: Fetcher<AppTemplatesResponse, { url: string }> = ({ url }) => {
  return get<AppTemplatesResponse>(url)
}

export const createApp: Fetcher<AppDetailResponse, { name: string; icon_type?: AppIconType; icon?: string; icon_background?: string; mode: AppMode; description?: string; config?: ModelConfig; default_language: Language; white_list_websites: string[] }> = ({ name, icon_type, icon, icon_background, mode, description, config }) => {
  return post<AppDetailResponse>('apps', { body: { name, icon_type, icon, icon_background, mode, description, model_config: config, default_language, auto_generate_datasets: true, white_list_websites } })
}

export const updateAppInfo: Fetcher<AppDetailResponse, { appID: string; name: string; icon_type: AppIconType; icon: string; icon_background?: string; description: string }> = ({ appID, name, icon_type, icon, icon_background, description }) => {
  return put<AppDetailResponse>(`apps/${appID}`, { body: { name, icon_type, icon, icon_background, description } })
}

export const copyApp: Fetcher<AppDetailResponse, { appID: string; name: string; icon_type: AppIconType; icon: string; icon_background?: string | null; mode: AppMode; description?: string }> = ({ appID, name, icon_type, icon, icon_background, mode, description }) => {
  return post<AppDetailResponse>(`apps/${appID}/copy`, { body: { name, icon_type, icon, icon_background, mode, description } })
}

export const exportAppConfig: Fetcher<{ data: string }, { appID: string; include?: boolean }> = ({ appID, include = false }) => {
  return get<{ data: string }>(`apps/${appID}/export?include_secret=${include}`)
}

export const importApp: Fetcher<AppDetailResponse, { data: string; name?: string; description?: string; icon_type?: AppIconType; icon?: string; icon_background?: string; white_list_websites: string[]; default_language: Language; bot_type: BotType }> = ({ data, name, description, icon_type, icon, icon_background, white_list_websites, default_language, bot_type }) => {
  return post<AppDetailResponse>('apps/import', { body: { data, name, description, icon_type, icon, icon_background, auto_generate_datasets: true, white_list_websites, default_language, bot_type } })
}

export const importAppFromUrl: Fetcher<AppDetailResponse, { url: string; name?: string; description?: string; icon?: string; icon_background?: string }> = ({ url, name, description, icon, icon_background }) => {
  return post<AppDetailResponse>('apps/import/url', { body: { url, name, description, icon, icon_background } })
}

export const switchApp: Fetcher<{ new_app_id: string }, { appID: string; name: string; icon_type: AppIconType; icon: string; icon_background?: string | null }> = ({ appID, name, icon_type, icon, icon_background }) => {
  return post<{ new_app_id: string }>(`apps/${appID}/convert-to-workflow`, { body: { name, icon_type, icon, icon_background } })
}

export const deleteApp: Fetcher<CommonResponse, string> = (appID) => {
  return del<CommonResponse>(`apps/${appID}`, { body: { auto_delete_datasets: true } })
}

export const updateAppSiteStatus: Fetcher<AppDetailResponse, { url: string; body: Record<string, any> }> = ({ url, body }) => {
  return post<AppDetailResponse>(url, { body })
}

export const updateAppApiStatus: Fetcher<AppDetailResponse, { url: string; body: Record<string, any> }> = ({ url, body }) => {
  return post<AppDetailResponse>(url, { body })
}

// path: /apps/{appId}/rate-limit
export const updateAppRateLimit: Fetcher<AppDetailResponse, { url: string; body: Record<string, any> }> = ({ url, body }) => {
  return post<AppDetailResponse>(url, { body })
}

export const updateAppSiteAccessToken: Fetcher<UpdateAppSiteCodeResponse, { url: string }> = ({ url }) => {
  return post<UpdateAppSiteCodeResponse>(url)
}

export const updateAppSiteConfig = ({ url, body }: { url: string; body: Record<string, any> }) => {
  return post<AppDetailResponse>(url, { body })
}

export const getAppDailyConversations: Fetcher<AppDailyConversationsResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AppDailyConversationsResponse>(url, { params })
}

export const getWorkflowDailyConversations: Fetcher<WorkflowDailyConversationsResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<WorkflowDailyConversationsResponse>(url, { params })
}

export const getAppStatistics: Fetcher<AppStatisticsResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AppStatisticsResponse>(url, { params })
}

export const getAppDailyEndUsers: Fetcher<AppDailyEndUsersResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AppDailyEndUsersResponse>(url, { params })
}

export const getAppTokenCosts: Fetcher<AppTokenCostsResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AppTokenCostsResponse>(url, { params })
}

export const getAppDailyMessages: Fetcher<AppDailyMessagesResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AppDailyMessagesResponse>(url, { params })
}

export const updateAppModelConfig: Fetcher<UpdateAppModelConfigResponse, { url: string; body: Record<string, any> }> = ({ url, body }) => {
  return post<UpdateAppModelConfigResponse>(url, { body })
}

// For temp testing
export const fetchAppListNoMock: Fetcher<AppListResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AppListResponse>(url, params)
}

export const fetchApiKeysList: Fetcher<ApikeysListResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ApikeysListResponse>(url, params)
}

export const delApikey: Fetcher<CommonResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return del<CommonResponse>(url, params)
}

export const createApikey: Fetcher<CreateApiKeyResponse, { url: string; body: Record<string, any> }> = ({ url, body }) => {
  return post<CreateApiKeyResponse>(url, body)
}

export const validateOpenAIKey: Fetcher<ValidateOpenAIKeyResponse, { url: string; body: { token: string } }> = ({ url, body }) => {
  return post<ValidateOpenAIKeyResponse>(url, { body })
}

export const updateOpenAIKey: Fetcher<UpdateOpenAIKeyResponse, { url: string; body: { token: string } }> = ({ url, body }) => {
  return post<UpdateOpenAIKeyResponse>(url, { body })
}

export const generationIntroduction: Fetcher<GenerationIntroductionResponse, { url: string; body: { prompt_template: string } }> = ({ url, body }) => {
  return post<GenerationIntroductionResponse>(url, { body })
}

export const fetchAppVoices: Fetcher<AppVoicesListResponse, { appId: string; language?: string }> = ({ appId, language }) => {
  language = language || 'en-US'
  return get<AppVoicesListResponse>(`apps/${appId}/text-to-audio/voices?language=${language}`)
}

export const fetchMessageList = (appId: string, params: Record<string, any> & { message_ids: string[] }) => {
  return get(`apps/${appId}/messages`, { params })
}

// Tracing
export const fetchTracingStatus: Fetcher<TracingStatus, { appId: string }> = ({ appId }) => {
  return get(`/apps/${appId}/trace`)
}

export const updateTracingStatus: Fetcher<CommonResponse, { appId: string; body: Record<string, any> }> = ({ appId, body }) => {
  return post(`/apps/${appId}/trace`, { body })
}

export const fetchTracingConfig: Fetcher<TracingConfig & { has_not_configured: true }, { appId: string; provider: TracingProvider }> = ({ appId, provider }) => {
  return get(`/apps/${appId}/trace-config`, {
    params: {
      tracing_provider: provider,
    },
  })
}

export const addTracingConfig: Fetcher<CommonResponse, { appId: string; body: TracingConfig }> = ({ appId, body }) => {
  return post(`/apps/${appId}/trace-config`, { body })
}

export const updateTracingConfig: Fetcher<CommonResponse, { appId: string; body: TracingConfig }> = ({ appId, body }) => {
  return patch(`/apps/${appId}/trace-config`, { body })
}

export const removeTracingConfig: Fetcher<CommonResponse, { appId: string; provider: TracingProvider }> = ({ appId, provider }) => {
  return del(`/apps/${appId}/trace-config?tracing_provider=${provider}`)
}

export const getUserFeedbackDistribution: Fetcher<UserFeedbackDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<UserFeedbackDistributionResponse>(url, { params })
}

export const getConsultationScenarioDistribution: Fetcher<ConsultationScenarioDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ConsultationScenarioDistributionResponse>(url, { params })
}

export const getConsultationIntentionDistribution: Fetcher<ConsultationIntentionDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ConsultationIntentionDistributionResponse>(url, { params })
}

export const getConsultationProductDistribution: Fetcher<ConsultationProductDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ConsultationProductDistributionResponse>(url, { params })
}

export const getUserCountDistribution: Fetcher<ReachOutUserCountDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ReachOutUserCountDistributionResponse>(url, { params })
}

export const getReachOutReasonDistribution: Fetcher<ReachOutReasonDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ReachOutReasonDistributionResponse>(url, { params })
}

export const getReachOutChannelDistribution: Fetcher<ReachOutChannelDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ReachOutChannelDistributionResponse>(url, { params })
}

export const getLackKnowledgeRate: Fetcher<LackKnowledgeRateResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<LackKnowledgeRateResponse>(url, { params })
}

export const getLackKnowledgeIntentionDistribution: Fetcher<LackKnowledgeIntentionDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<LackKnowledgeIntentionDistributionResponse>(url, { params })
}

export const getLackKnowledgeProductDistribution: Fetcher<LackKnowledgeProductDistributionResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<LackKnowledgeProductDistributionResponse>(url, { params })
}

export const getIntentAndSkuList: Fetcher<IntentAndSkuListResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<IntentAndSkuListResponse>(url, { params })
}

export const getCommodityCardConversionFunnel: Fetcher<CommodityCardConversionFunnelResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<CommodityCardConversionFunnelResponse>(url, { params })
}

export const getInteractionAIUserCount: Fetcher<InteractionAIUserCountResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<InteractionAIUserCountResponse>(url, { params })
}

export const getConversationAndMessageCount: Fetcher<ConversationAndMessageCountResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<ConversationAndMessageCountResponse>(url, { params })
}

export const getAvgChatDuration: Fetcher<AvgChatDurationResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AvgChatDurationResponse>(url, { params })
}

export const getUserSatisfactionRate: Fetcher<UserSatisfactionRateResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<UserSatisfactionRateResponse>(url, { params })
}

export const getAverageSessionInteractions: Fetcher<AverageSessionInteractionsResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<AverageSessionInteractionsResponse>(url, { params })
}

export const getLaborTransferRateChart: Fetcher<LaborTransferRateChartResponse, { url: string; params: Record<string, any> }> = ({ url, params }) => {
  return get<LaborTransferRateChartResponse>(url, { params })
}

export const exportData = (appId: string, params: Record<string, any>) => {
  const headers = new Headers()
  headers.append('Content-Type', ContentType.download)
  return get<any>(`apps/${appId}/dashboard/export`, { headers, params })
}
